const calendario = {
    
    makeDays: function (from, to) {
        let current = ""+from;
        let dateCurrent = calendario.utcDate(current);
        let dateLast = calendario.utcDate(to);
        let days = [];
        let safe = 0;
        let maxSafe = 6000
        while ((safe < maxSafe) && (dateCurrent <= dateLast)) {
            safe += 1;
            if (safe===maxSafe) {
                alert("Hai raggiunto il limite massimo di " +maxSafe+" giorni.");
            }
            let dayString = calendario.date2ymd(dateCurrent);
            let day = {
                date: dayString,
            }
            if (!days.find(el=>el.date===dayString)) {
                days.push(day);
            }
            dateCurrent = calendario.utcDate(calendario.addDays(dayString, 1));
        }
        return days;
    },

      prevWeek: function (ymd) {
        let firstMonday = ymd;//calendario.getMonday(ymd);
        let from = calendario.addDays(firstMonday, -7);
        let to = calendario.addDays(firstMonday,0);
        return {
          from: from,
          to: to
        }
      },

      nextWeek: function (ymd) {
        let lastMonday = ymd;//calendario.getMonday(ymd);
        let from = calendario.addDays(lastMonday,0);
        let to = calendario.addDays(lastMonday, +7);
        return {
          from: from,
          to: to
        }
      },

      addDays: function(ymd, days) {
        let date = calendario.utcDate(ymd);
        date.setUTCDate(date.getUTCDate() + days)
        return calendario.date2ymd(date);
      },

    formatDateShort: function(date) {
        const opzioni = { day: 'numeric', month: 'short', year: 'numeric' };
        return new Intl.DateTimeFormat('it-IT', opzioni).format(date);
    },

    ymd2dateShort: function(ymd) {
        const date = calendario.utcDate(ymd);
        const opzioni = { day: 'numeric', month: 'short', year: 'numeric' };
        return new Intl.DateTimeFormat('it-IT', opzioni).format(date);
    },



    getMonthName: function (ymd) {
        const date = calendario.utcDate(ymd);
        return date.toLocaleString("default",{month:"long"})
    },

    getMonthYear: function (ymd) {
        const date = calendario.utcDate(ymd);
        return date.toLocaleString("default",{month:"long", year: 'numeric'})
    },

    getWeek: function (ymd) {
        const date = calendario.utcDate(ymd);
        var onejan = new Date(date.getFullYear(), 0, 1);
        return Math.ceil((((this - onejan) / 86400000) + onejan.getDay() + 1) / 7);
    },

    getFirstDayOfThisMonth: function(ymd) {
        return calendario.getFirstDayOfNthMonth(ymd, 0);
    },

    getFirstDayOfNextMonth: function(ymd) {
        return calendario.getFirstDayOfNthMonth(ymd, 1);
    },

    getFirstDayOfPrevMonth: function(ymd) {
        return calendario.getFirstDayOfNthMonth(ymd, -1);
    },

    dateFirstDayOfNthMonth: function(ymd, inc) {
        const date = calendario.utcDate(ymd);
        const out = calendario.utcDate(date.getUTCFullYear(), date.getUTCMonth() + inc, 1);
        return out;
    },

    getFirstDayOfNthMonth: function(ymd, inc) {
        const firstDay = calendario.dateFirstDayOfNthMonth(ymd,inc);
        return calendario.date2ymd(firstDay);
    },

    timezone: function () {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    },
    localDate: function (date) {
        let minutes = date.getTimezoneOffset();
        let localTimestamp = date.valueOf() - minutes*60*1000;
        return new Date(localTimestamp);
    },

    date2ymd: function (date) {
        if (typeof date === "string") return date;
        if (!date) {
            date = calendario.utcDate();
        }
        return date.toISOString().substr(0,10);
    },

    /** alias date2ymd */
    formatDateYmd: function (date) {
        return calendario.date2ymd(date);
    },

    utcDate: function (ymd) {
        if (!ymd) {
            ymd = new Date().toISOString();
        } else if (arguments.length===3) {
            ymd = new Date(Date.UTC(arguments[0], arguments[1], arguments[2])).toISOString();
        } else if (typeof ymd === 'number') {
            ymd = new Date(ymd).toISOString();
        } else if (typeof ymd !== 'string') {
            ymd = ymd.toISOString();
        }
        let dateString = ymd.substr(0,10);
        let utc = dateString+"T00:00:00.000Z";
        return new Date(utc);
    },

    getWeekDay: function (ymd, timezone) {
        const date = calendario.utcDate(ymd);
        var weekDay = date.toLocaleString("en-US", {
            timeZone: timezone,
            weekday: 'long'
        });
        return weekDay;
    },

    getMonday: function (ymd = "") {
        let date = calendario.dateMonday(ymd);
        if (!date) return false;
        return calendario.date2ymd(date);
    },

    getDay: function (ymd) { 
        let ref = calendario.utcDate(ymd);
        return ref.getUTCDay();
    },
    getDate: function (ymd) { 
        let ref = calendario.utcDate(ymd);
        return ref.getUTCDate();
    },

    dateMonday: function (ymd) {
        let ref = calendario.utcDate(ymd);
        let incDay = 0;
        let weekDay = calendario.getDay(ymd);
        if (weekDay === 0) {
            incDay = 6;
        } else {
            incDay = weekDay - 1;
        }
        let first = calendario.getDate(ymd) - incDay; // First day is the day of the month - the day of the week
        ref.setUTCDate(first);
        return ref;
    },
    inLimits: function (start, end, day) {
        const dateLimitStart = calendario.utcDate(start);
        const dateLimitEnd = calendario.utcDate(end);
        const date = calendario.utcDate(day);
        const inLimits =  (dateLimitStart.getTime() <= date.getTime()) && (dateLimitEnd.getTime() >= date.getTime());
        return inLimits;
    },
}
export default calendario;

