import { env } from "@mixins/environment.js"
import {api, URLparams } from "@mixins/api.js";
import _cal from "@src/js/vue/mixins/calendario.js";
const endpoint = env.api_base_url +  '/api/v2/misurazione.php';
import _misurazioneParametri from "@cached/misurazioneParametri.json"
const _misurazioni = {
    keys: [],
    misurazioni: [],
    maxItems: 1,

    makeKey: function (item) {
        return item.parametro+'-'+item.data;
    },

    misurazioneHasValori: function (m) {
        if (!m.valori) return false;
        return _misurazioni.valoriNotEmpty(m.valori);
    },

    valoriNotEmpty: function (valori) {
        let countV = 0;
        for (const k in valori) {
            if (valori[k] > 0) {
                countV ++;
            }
        }
        return countV;
    },

    misurazioniNotInRange: function (misurazioni, da, a) {
        let days = _cal.makeDays(da,a);
        let dates = days.map(el=>el.date);
        let filtered = misurazioni.filter((m)=>{
            const inDates = dates.indexOf(m.data) 
            return inDates === -1;
        });
        return filtered;
    },

    misurazioniConValore: function (misurazioni) {
        if (!misurazioni) return [];
        return misurazioni.filter(_misurazioni.misurazioneHasValori);
    },

    loadParametri: function () {
        return _misurazioneParametri;
    },
    saveMisurazione: async function (data, parametro, valori) {
        const payload = {
            data: data,
            parametro: parametro,
            valori: valori,
        }
        let url = endpoint;
        return await   api(url, "POST", payload);
    },

    deleteMisurazione: async function (data, parametro) {
        const payload = {
            data: data,
            parametro: parametro,
        }
        let url = endpoint;
        return await   api(url, "delete", payload);
    },

    loadLast: async function (atletaId, parametro) {
        const payload = {
            last: 1,
            parametro: parametro.codice,
            utente_id: atletaId,
        }
        const cp = URLparams(payload);
        let url = `${endpoint}?${cp}`;
        const result = await api(url, "GET", null);
        if (result.success) {
            return result.data;
        } else {
            return null;
        }
    },
    loadMisurazioni: async function (atletaId,da,a) {
        let params = {
            utente_id: atletaId,
            da: da,
            a: a,
        };
        const qs = '?' + new URLSearchParams(params).toString()
        const url = endpoint + qs;
        const result = await api(url, "GET");
        if (!(result.success)) return [];
        return result.data;
    },
    addMisurazioni: async function (misurazioni) {
        misurazioni.forEach((misurazione)=>{
            let key = _misurazioni.makeKey(misurazione);
            let index = _misurazioni.keys.indexOf(key);
            if (index == -1) {
                _misurazioni.misurazioni.push(misurazione);
                _misurazioni.key.push(key);
            } else {
                _misurazioni.misurazioni.splice(index,1,misurazione);
            }

        });
    }
}
export default _misurazioni;
